import './prototype-sidebar.css';
import logo from './logo3d.gif';
import {NavLink} from "react-router-dom";
import {CorruptionText} from "../../components/corruption-text";

export const PrototypeSidebar = () => {
    return (
        <div className='prototype-sidebar'>
            <img src={logo} alt='team logo' />
            <NavLink className="prototype-sidebar-link" to="/prototype">
                <CorruptionText text='Home!' />
            </NavLink>
            <NavLink className="prototype-sidebar-link" to="/prototype/logs">
                <CorruptionText text='Logs' />
            </NavLink>
            <NavLink className="prototype-sidebar-link" to="/prototype/about">
                <CorruptionText text='About' />
            </NavLink>
        </div>
    )
};