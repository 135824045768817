import {useState, useEffect} from 'react';
export const usePromiseEffect =(effect, deps) => {
    const [state, setState] = useState({
        status: 'idle',
        value: null,
        error: null,
    })

    useEffect(() => {
        effect()
            .then((value) => setState({ status: 'fulfilled', value, error: null }))
            .catch((error) => setState({ status: 'rejected', value: null, error }))
        // eslint-disable-next-line
    }, deps)
    return state
}