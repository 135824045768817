import {useRef, useCallback, useEffect, useState} from "react";
import {SectionHeader} from "../team/section-header";
import swoleswoleswoleWebm from './swoleswoleswole.webm';
import placeholder from './placeholder.png';
import './about-page.css';
import gkScrimblo from './gkscrimblo.webp';
import gkAudio from './GK_necoarc.mp3';
import chileanFlag from './chilean flag.gif';
import chileAnthem from './El Soldado Más Fuerte de Chile.wav';
import {shouldUseWebm} from "../../should-use-webm";

export const AboutPage = () => {
    const [scrimbloClass, setScrimbloClass] = useState('');
    const [chileUpClass, setChileUpClass] = useState('');
    const videoRef = useRef(null);
    const audioRef = useRef(null);
    const chileAudioRef = useRef(null);
    const swoleClicked = useCallback(() => {
        videoRef.current.play();
    }, [videoRef]);
    useEffect(() => {
        if(videoRef.current){
            videoRef.current.volume = 0.5;
        }
    }, [videoRef]);
    const scrimbloClick = useCallback(() => {
        audioRef.current.play();
        setScrimbloClass('animate');
        setTimeout(() => {
            setScrimbloClass('');
        }, 3000);
    }, [setScrimbloClass]);

    const astroClick = useCallback(() => {
        setChileUpClass('up');
        chileAudioRef.current.volume = 0.3;
        chileAudioRef.current.play();
        setTimeout(() => {
            setChileUpClass('');
        }, 100000);
    }, [setChileUpClass]);

    return (
        <div className='about-page'>
            {
                shouldUseWebm() && (
                    <div className='about-page-easter-egg'>
                        <video height='100%' width='100%' ref={videoRef} src={swoleswoleswoleWebm} />
                    </div>
                )
            }
            <div className='about-page-content'>
                <SectionHeader text='What is INVALID GATEWAY?' />
                <div className='about-page-inner-content'>
                    <div className='about-blurb-and-image'>
                        <div className='about-page-blurb'>
                            We are Invalid Gateway! A stream team built upon the passion of our individual members, and the drive to promote positive reinforcement towards other creators. We are made up of 10 members from all walks of life and creation. The main goal of our group is to create a vast network of various creators and passionate people that influence each other to pursue their goals and create a engaging environment within the entertainment sphere. Ultimately just a bunch of friends having a great time, and hoping to make others happy! Let's make a Connection!
                        </div>
                        <div className='about-page-team-image-and-credits'>
                            <img className='about-page-team-image' src={placeholder} alt='the team!' />
                            <a href='https://twitter.com/PIPERMAYDAY' className='about-page-team-image-credits'>
                                art by PIPERMAYDAY
                            </a>
                        </div>

                    </div>
                    <div className='about-page-section'>
                        <div className='about-page-header'>
                            Who made the site?
                        </div>
                        <div className='about-page-credits'>
                            <div className='about-page-credit'>
                                <a className='about-page-credit-name' href='https://dawnatdusk.neocities.org/'>
                                    Fondant
                                </a>
                                <div className='about-page-credit-thing'>
                                    Design and Assets
                                </div>
                            </div>
                            <div className='about-page-credit'>
                                <button className='about-page-credit-name' onClick={swoleClicked}>
                                    Swolekat
                                </button>
                                <div className='about-page-credit-thing'>
                                    Development
                                </div>
                            </div>
                            <div className='about-page-credit'>
                                <button className='about-page-credit-name' onClick={astroClick}>
                                    Astrowell
                                </button>
                                <div className='about-page-credit-thing'>
                                    Music and Sounds
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='about-page-scrimblo' onClick={scrimbloClick}>
                        <img className={`${scrimbloClass} after-image-1`} src={gkScrimblo} alt='gk in her natural form' />
                        <img className={`${scrimbloClass} after-image-2`} src={gkScrimblo} alt='gk in her natural form' />
                        <img className={scrimbloClass} src={gkScrimblo} alt='gk in her natural form' />
                    </div>
                    <audio src={gkAudio} ref={audioRef} />
                    <img className={`chilean-flag ${chileUpClass}`} src={chileanFlag} alt='Chilean Flag' />
                    <audio src={chileAnthem} ref={chileAudioRef} />
                </div>

            </div>
        </div>
    )
};