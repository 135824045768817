import {useState, useCallback} from 'react';
import './video.css';

export const Video = ({video, isBig}) => {
    const [thumbnailError, setThumbnailError] = useState(false);

    const onError = useCallback(() => {setThumbnailError(true)}, [setThumbnailError]);

    if(thumbnailError){
        return null;
    }

    return (
        <a className={`video ${isBig ? 'big': ''}`} href={video.url}>
            <img className='video-background' src={video.thumbnail} onError={onError} alt={video.name} />
            <div className='video-info'>
                <div className='video-title'>
                    {video.name}
                </div>
            </div>
        </a>
    )
};