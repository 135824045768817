import {useDataContext} from "../../../data-context";
import {TeamMember} from './team-member';
import './team-grid.css';
import {useTeamContext} from "../team-context";

export const TeamGrid = () => {
    const {showAltMembers} = useTeamContext();
    const {members, altMembers} = useDataContext();
    const membersToShow = showAltMembers ? altMembers : members;

    return (
        <div className='team-grid'>
            {
                membersToShow.map(member => (
                    <TeamMember
                        key={member.id}
                        memberId={member.id}
                    />
                ))
            }
        </div>
    )
};