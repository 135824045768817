import mavProfile from './assets/altmembers/mav.png';
import eightBitProfile from './assets/altmembers/8bit.png';
import swolebaeProfile from './assets/altmembers/swolebae.png';
import sippeProfile from './assets/altmembers/sippe.png';

export const AltMembers = [
    {
        id: 'mav',
        banner: 'https://swolekat.github.io/invalid-gateway-website/banners/glitch_kitten.png',
        clips: [],
        description: 'GK\'s rescue datahound. Mav has been by GK\'s side for a long time. He\'s very affectionate, and often sleeps for long periods of time.',
        aliases: ['Mavvers', 'Bubby'],
        links: [],
        mainPlatform: 'twitch',
        name: 'Mav',
        platformId: 0,
        profilePicture: mavProfile,
        videos: [],
        vods: [],
        youtubeChannelId: '',
    },
    {
        id: 'sippe',
        banner: 'https://swolekat.github.io/invalid-gateway-website/banners/azzy.jpg',
        clips: [],
        description: 'Azurine came across Sippe after her exile. He was a wanderer, like she was, but was looking to settle down. So, Azurine offered him a place at the Foxtail and he was happy to run the occasional errands to the village. The villagers love and welcome Sippe, and send Azurine gifts through him on occasion.',
        aliases: [],
        links: [],
        mainPlatform: 'twitch',
        name: 'Sippe',
        platformId: 0,
        profilePicture: sippeProfile,
        videos: [],
        vods: [],
        youtubeChannelId: '',
    },
    {
        id: '8bit',
        banner: 'https://swolekat.github.io/invalid-gateway-website/banners/glitch_kitten.png',
        clips: [],
        description: 'A Voidal Chao hatched during the summer festival. Not much is known about his origin or his alternate forms. Is currently in it\'s infant stage. It is very clingy to GK.',
        aliases: ['The Baby', 'The Boy', 'The Little Guy'],
        links: [],
        mainPlatform: 'twitch',
        name: '8 Bit',
        platformId: 0,
        profilePicture: eightBitProfile,
        videos: [],
        vods: [],
        youtubeChannelId: '',
    },
    {
        id: 'swolebae',
        banner: 'https://swolekat.github.io/invalid-gateway-website/banners/swolekat.png',
        clips: [],
        description: 'Swolebae is a kawaii catgirl VTuber with a big passion for games and anime, UwU! Rocking a red bikini and M-cup boobs, her girly and cutesy charm is sure to win you over, nyan~ Living with her beau, Swolekat, she spends her days streaming, fangirling over all things otaku, and enjoyin\' some scrumptious Japanese cuisine, ara ara! Whether it\'s learning about new streaming tech, cosplaying as her fave game characters, or trading banter with her and Swolekat\'s streamer buddies, every day with Swolebae is a day filled with love, laughs, and lots of sugoi adventures!',
        aliases: ['Bae'],
        links: [],
        mainPlatform: 'twitch',
        name: 'Swolebae',
        platformId: 0,
        profilePicture: swolebaeProfile,
        videos: [],
        vods: [],
        youtubeChannelId: '',
    },
];