import {useState, useCallback} from 'react';

import './fighting-game-intro.css';
import BackgroundImage from './assets/background.png';
import CurtainsImage from './assets/curtains.png';
import CharachterSelect from './assets/charSelect.png';
import Spotlight from './assets/spotlight.png';
import P1Select from './assets/p1SelectFull.png';
import P2Select from './assets/p2SelectFull.png';

import {characterInfo, randomCharacter} from "./character-info";
import {CharachterBio} from "./charachter-bio";
import {PlayerName} from "./player-name";
import {PlayerPortrait} from "./player-portrait";
import {getAVeryLongPath} from "./getCharactersInbetween";

const zeroRowTop = 637;
const oneRowTop = 729;
const twoRowTop = 816;
const zeroColumnLeft = 580;
const charSelectWidth = 68.5;

const getStyleFromPosition = ({row, column}) => {
    const left = zeroColumnLeft + (column * charSelectWidth);
    let top = zeroRowTop;
    if(row === 1){
        top = oneRowTop;
    }
    if(row === 2){
        top = twoRowTop;
    }
    return ({
        left: `${left}px`,
        top: `${top}px`,
    })
};

export const FightingGameIntro = () => {
    const [player1, setPlayer1] = useState(randomCharacter);
    const [player2, setPlayer2] = useState(randomCharacter);
    const [endingPlayer1, setEndingPlayer1] = useState(randomCharacter);
    const [endingPlayer2, setEndingPlayer2] = useState(randomCharacter);

    const onPlayer1SelectChange = useCallback((e) => {
        const matchingPlayer = characterInfo.find(p => p.name === e.target.value);
        setEndingPlayer1(matchingPlayer);
    }, []);
    const onPlayer2SelectChange = useCallback((e) => {
        const matchingPlayer = characterInfo.find(p => p.name === e.target.value);
        setEndingPlayer2(matchingPlayer);
    }, []);


    const startAnimation = useCallback(() => {
        const charactersToTravelForPlayer1 = getAVeryLongPath(randomCharacter, endingPlayer1);
        const charactersToTravelForPlayer2 = getAVeryLongPath(randomCharacter, endingPlayer2);
        const longerPath = Math.max(charactersToTravelForPlayer1.length, charactersToTravelForPlayer2.length);
        let index = 0;
        let myInterval = setInterval(() => {
            const newP1 = charactersToTravelForPlayer1[index];
            const newP2 = charactersToTravelForPlayer2[index];
            if(newP1){
                setPlayer1(newP1);
            }
            if(newP2){
                setPlayer2(newP2);
            }
            index++;
            if(index > longerPath -1){
                clearInterval(myInterval);
            }
        }, 1000);
    }, [setPlayer1, setPlayer2, endingPlayer1, endingPlayer2]);

    return (
        <div className='fighting-game-intro-page'>
            <div className='fighting-game-intro-content'>
                <img className='fighting-game-intro-background-image' alt='fighting game background' src={BackgroundImage} />
                <img className='fighting-game-intro-background-layer' alt='fighting game background' src={CurtainsImage} />
                <img className='flag-portrait player1' alt='fighting game player1 flag' src={player1.assets.selectPortrait} />
                <img className='flag-portrait player2' alt='fighting game player2 flag' src={player2.assets.selectPortrait} />
                <PlayerName name={player1.name} isP1/>
                <PlayerName name={player2.name}/>
                <PlayerPortrait name={player1.name} portrait={player1.assets.p1Portrait} isP1 />
                <PlayerPortrait name={player2.name} portrait={player2.assets.p2Portrait} flipped={player2.assets.onePortrait} />
                <CharachterBio charachterInfo={player1.info} name={player1.name} isp1 />
                <CharachterBio charachterInfo={player2.info} name={player2.name} />
                <img className='fighting-game-intro-background-layer' alt='fighting game background' src={CharachterSelect} />
                <img className='fighting-game-intro-background-layer' alt='fighting game background' src={Spotlight} />
                {characterInfo.map(({name, position, assets}) => {
                    const {selectPortrait} = assets;
                    return (
                        <img src={selectPortrait} style={getStyleFromPosition(position)} alt={`${name} on the charachter select`} key={name} className='fighting-game-intro-select-portrait' />
                    )
                })}
                <img className='player-select p1' alt='player 1 select icon' src={P1Select} style={getStyleFromPosition(player1.position)}/>
                <img className='player-select p2' alt='player 2 select icon' src={P2Select} style={getStyleFromPosition(player2.position)}/>
            </div>
            <div className='fighting-game-intro-controls'>
                <div>
                    <div>
                        Player 1
                    </div>
                    <select onChange={onPlayer1SelectChange} value={endingPlayer1.name}>
                        {characterInfo.map((info) => {
                            return (
                                <option value={info.name} key={info.name}>{info.name}</option>
                            );
                        })}
                    </select>
                </div>
                <div>
                    <div>
                        Player 2
                    </div>
                    <select onChange={onPlayer2SelectChange} value={endingPlayer2.name}>
                        {characterInfo.map((info) => {
                            return (
                                <option value={info.name} key={info.name}>{info.name}</option>
                            );
                        })}
                    </select>
                </div>
                <div>
                    <button className='go-button' onClick={startAnimation}>
                        Go!
                    </button>
                </div>
            </div>
        </div>
    );
};