import {usePrototypeContext} from "../prototype-context";

export const CorruptionDisplay = ({children, percentage}) => {
    const {corruptionPercentage} = usePrototypeContext();
    if(percentage !== corruptionPercentage){
        return null;
    }
    return (
        <>
            {children}
        </>
    )
};