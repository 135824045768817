import {useRef, useCallback, useEffect} from 'react';
import {NavLink} from 'react-router-dom'
import Logo from '../../assets/TypeW.svg';
import './sidebar.css';
import {SpinnyVortex} from "../spinny-vortex";
import c from './song/C.wav';
import dLow from './song/D_low.wav';
import dHigh from './song/D_high.wav';
import f from './song/F.wav';
import song from './song/song.wav';
import badSong from './song/song_of_bad.wav';

let lastNotesPlayed = [];
const songPattern = ['dlow', 'f', 'dhigh', 'dlow', 'f', 'dhigh'];
let numberOfTimesPlayed = 0;

export const Sidebar = ({toggleOpen}) => {
    const dLowRef = useRef();
    const dHighRef = useRef();
    const fRef = useRef();
    const cRef = useRef();
    const songRef = useRef();
    const badSongRef = useRef();

    const playNote = useCallback((note) => {
        lastNotesPlayed.push(note);
        if(lastNotesPlayed.length > 6) {
            lastNotesPlayed = lastNotesPlayed.slice(1, 7);
        }
        if(note === 'dlow'){
            dLowRef.current.play();
        }
        if(note === 'dhigh'){
            dHighRef.current.play();
        }
        if(note === 'f'){
            fRef.current.play();
        }
        if(note === 'c'){
            cRef.current.play();
        }
        if(songPattern.join() === lastNotesPlayed.join()){
            numberOfTimesPlayed ++;
            lastNotesPlayed = [];
            if(numberOfTimesPlayed % 4 === 0){
                setTimeout(() => badSongRef.current.play(), 1000);
                return;
            }
            setTimeout(() => songRef.current.play(), 1000);
        }
    }, []);

    useEffect(() => {
        dHighRef.current.volume = 0.5;
        dLowRef.current.volume = 0.5;
        fRef.current.volume = 0.5;
        cRef.current.volume = 0.5;
        songRef.current.volume = 0.5;
        badSongRef.current.volume = 0.5;
    }, [dLowRef, dHighRef, fRef, cRef, songRef, badSongRef]);


    return (
        <div className="sidebar">
            <div className="nav-section">
                <div className="close-button-section">
                    <div className="spacer hidden">
                    </div>
                    <div className="close-sidebar-button-wrapper">
                        <button className={`close-sidebar-button`} onClick={toggleOpen}>
                            <svg x="0px" y="0px" viewBox="0 0 96 96">
                                <polygon points="96,14 82,0 48,34 14,0 0,14 34,48 0,82 14,96 48,62 82,96 96,82 62,48 "/>
                            </svg>
                        </button>
                    </div>
                </div>
                <div className="nav-title">
                    <div className="spacer hidden">
                    </div>
                    <div className="nav-title-name">
                        <img src={Logo} alt='Invalid Gateway Logo' />
                    </div>
                </div>
                <div className="nav-item">
                    <div className="spacer">
                    </div>
                    <NavLink className="nav-item-name" to="/" onClick={() => playNote('dhigh')}>
                        <i>‣</i> Home
                    </NavLink>
                </div>
                <div className="nav-item">
                    <div className="spacer">
                    </div>
                    <NavLink className="nav-item-name" to="/team" onClick={() => playNote('c')}>
                        <i>‣</i> Team
                    </NavLink>
                </div>
                <div className="nav-item">
                    <div className="spacer">
                    </div>
                    <NavLink className="nav-item-name" to='/blog' onClick={() => playNote('f')}>
                        <i>‣</i> Blog
                    </NavLink>
                </div>
                <div className="nav-item">
                    <div className="spacer">
                    </div>
                    <NavLink className="nav-item-name" to='/about' onClick={() => playNote('dlow')}>
                        <i>‣</i> About
                    </NavLink>
                </div>
            </div>
            <div className='nav-logo'>
                <SpinnyVortex />
            </div>
            <div className='nav-audios'>
                <audio src={dLow} ref={dLowRef} />
                <audio src={dHigh} ref={dHighRef} />
                <audio src={f} ref={fRef} />
                <audio src={c} ref={cRef} />
                <audio src={song} ref={songRef} />
                <audio src={badSong} ref={badSongRef} />
            </div>
        </div>
    )
};