import {useState, useCallback, useEffect} from "react";
import {Outlet, useLocation} from "react-router-dom";
import {Sidebar} from "./sidebar";
import "./layout.css";
import {MobileHeader} from "./mobile-header";

const routesToJustOutlet = ['secret', 'prototype', 'misc', 'foa2024'];

export const Layout = () => {
    const location = useLocation();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const toggleSidebar = useCallback(() => {
        setSidebarOpen(open => !open);
    }, []);
    useEffect(() => {
        setSidebarOpen(false);
    }, [location]);
    if(routesToJustOutlet.some(route => location.pathname.includes(route))){
        return (
            <Outlet />
        );
    }
    return (
        <div className="layout">
            <div className='mobile-header-wrapper'>
                <MobileHeader isOpen={sidebarOpen} toggleOpen={toggleSidebar} />
            </div>
            <div className={`sidebar-wrapper ${sidebarOpen ? 'open' : ''}`}>
                <Sidebar toggleOpen={toggleSidebar} />
            </div>
            <div className='main-content'>
                <Outlet />
            </div>
        </div>
    );
};