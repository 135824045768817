import rockHost from './assets/rockHost.png';
import focDescription from './assets/focDescription.webp';

import gkHost from './assets/gkHost.png';
import ppDescription from './assets/ppDescription.png';

import gkgermHost from './assets/gkgermHost.png';
import ridersDescription from './assets/ridersDescription.webp';

export const EventsList = [
    {
        title: 'Fact or Cap',
        titleDescription: 'Test your musical knowledge to find out if you can recognize the tunes of SEGA',
        hostIsPlural: false,
        hostText: 'Rockaway Carter',
        hostProfile: rockHost,
        descriptionImage: focDescription,
        description: 'SEGA has been working for AGES composing music for their games. Test your ears and memory to see if you can recall, or guess, the name, title or series they\'re from!'
    },
    {
        title: 'Pixul Panic',
        titleDescription: 'The Glitchy Gateway\'s annual racing tournament is being held in Ring Racers this year!',
        hostIsPlural: false,
        hostText: 'Glitch Kitten',
        hostProfile: gkHost,
        descriptionImage: ppDescription,
        description: 'Sign-up to try your hand at the wheel! BE WARNED: Chaos is the name of the game and only the strongest survive. Not for the faint of heart!'
    },
    {
        title: 'Sonic Riders DX Tournament',
        titleDescription: 'The Sonic Riders tourney is taking place once again!',
        hostIsPlural: true,
        hostText: 'Glitch Kitten & GermX',
        hostProfile: gkgermHost,
        descriptionImage: ridersDescription,
        description: 'A modified version of Sonic Riders. Chaos, and spotlights of various tech and skill are expected to hit the scene when this game takes the stage!'
    },
];