import {createContext, useContext, useMemo, useState} from "react";
import useInterval from "use-interval";

const PrototypeContext = createContext({});


export const PrototypeProvider = ({ children }) => {
    const [corruptionPercentage, setCorruptionPercentage] = useState(0);

    useInterval(() => {
        if(corruptionPercentage >= 100){
            return;
        }
        setCorruptionPercentage(corruptionPercentage+10);
    }, 120000);
    // }, 3000);

    const value = useMemo(() => ({
        corruptionPercentage
    }), [corruptionPercentage]);

    return <PrototypeContext.Provider value={value}>{children}</PrototypeContext.Provider>;
};

export const usePrototypeContext = () => {
    return useContext(PrototypeContext);
}