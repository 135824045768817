import beelzebella from './assets/beelzebella.png';
import brentzone from './assets/brentzone.png';
import dreadknux from './assets/dreadknux.png';
import hco from './assets/hco.jpg';
import icynato from './assets/icynato.png';
import lunar from './assets/lunar.png';
import nyte from './assets/nyte.png';
import retro from './assets/retro.png';
import rock from './assets/rock.png';
import skull92 from './assets/skull92.jpg';
import spark from './assets/spark.png';
import swolekat from './assets/swolekat.png';
import tetrakaynx from './assets/tetrakaynx.png';
import tcl from './assets/tcl.png';
import zinny from './assets/zinny.png';
import zonic from './assets/zonic.png';

export const GuestList = [
    {
        name: 'Beelzebella',
        tagline: 'Artist / VTuber',
        description: 'BZ is an immortal villain vtuber who streams with a model they made themself!',
        profile: beelzebella,
        socials: [
            {
                type: 'twitter',
                url: 'https://twitter.com/The_Beelzebella',
            },
            {
                type: 'twitch',
                url: 'https://www.twitch.tv/beelzebellaofficial',
            },
            {
                type: 'youtube',
                url: 'https://www.youtube.com/@beelzebellaofficial',
            },
        ],
    },
    {
        name: 'BrentZone',
        tagline: 'Streamer',
        description: 'Brent is both a classic gamer and sarcastic joker with a love for both Sonic (yay!) and Mario (boo!)',
        profile: brentzone,
        socials: [
            {
                type: 'twitter',
                url: 'https://twitter.com/BrentCornils',
            },
            {
                type: 'twitch',
                url: 'https://www.twitch.tv/brentzone',
            },
            {
                type: 'youtube',
                url: 'https://www.youtube.com/doctoreggmannega',
            },
        ],
    },
    {
        name: 'DreadKnux',
        tagline: 'Sonic Enthusiast',
        description: 'Founder of the Sonic Stadium and the GRAND OVERLORD of all TSS Network activites.',
        profile: dreadknux,
        socials: [
            {
                type: 'twitter',
                url: 'https://twitter.com/Dreadknux',
            },
            {
                type: 'twitter',
                url: 'https://twitter.com/Dreadknux',
            },
            {
                type: 'website',
                url: 'https://www.sonicstadium.org/',
            },
        ],
    },
    {
        name: 'HCO',
        tagline: 'YouTuber / VTuber',
        description: 'Greyscale Amy LEGEND from the karting community. Their content will make you say "Hey, come on! More of that please!"',
        profile: hco,
        socials: [
            {
                type: 'twitter',
                url: 'https://twitter.com/HeyHCO',
            },
            {
                type: 'twitch',
                url: 'https://www.twitch.tv/heyhco',
            },
            {
                type: 'youtube',
                url: 'https://www.youtube.com/@HeyHCO',
            },
        ],
    },
    {
        name: 'Icynato',
        tagline: 'Streamer',
        description: 'Icy is a variety streamer who dabbles in pretty much everything. ',
        profile: icynato,
        socials: [
            {
                type: 'twitter',
                url: 'https://twitter.com/Icynato',
            },
            {
                type: 'twitch',
                url: 'https://www.twitch.tv/icynato',
            },
            {
                type: 'youtube',
                url: 'https://www.youtube.com/icynato',
            },
        ],
    },
    {
        name: 'Lunar the Holo-Wolf',
        tagline: 'British VTuber',
        description: 'Be it as Lunar, Silvia, or Natalie, she loves Sonic, Fighting Games, and cheering on her friends!',
        profile: lunar,
        socials: [
            {
                type: 'twitter',
                url: 'https://twitter.com/LunarHoloWolf',
            },
            {
                type: 'twitch',
                url: 'https://www.twitch.tv/lunartheholowolf',
            },
        ],
    },
    {
        name: 'Nyte the Shapeshifter',
        tagline: 'Retro Gamer / VTuber',
        description: 'Spy brings back the games of old for not only nostalgia, but evil. This shape-shifter has many forms so you never quite know what to expect.',
        profile: nyte,
        socials: [
            {
                type: 'twitch',
                url: 'https://www.twitch.tv/spycrab_113',
            },
            {
                type: 'youtube',
                url: 'https://www.youtube.com/@spycrab113',
            },
        ],
    },
    {
        name: 'RetroStation',
        tagline: 'SBR2K Fan / Streamer',
        description: 'Kick Punch Block\'s resident variety gamer with a soft spot for old-school games and art styles!',
        profile: retro,
        socials: [
            {
                type: 'twitch',
                url: 'https://www.twitch.tv/retrostation',
            },
        ],
    },
    {
        name: 'RockawayCarter',
        tagline: 'Fighting Game Enthusiast / VTuber',
        description: 'A prolific fighting gamer and a jack of all trades, Rock plays what he wants and he hopes you\'ll join him in the ring!',
        profile: rock,
        socials: [
            {
                type: 'twitter',
                url: 'https://twitter.com/RockawayCarter',
            },
            {
                type: 'twitch',
                url: 'https://www.twitch.tv/rockawaycarter',
            },
            {
                type: 'youtube',
                url: 'https://www.youtube.com/c/RockawayCarter',
            },
        ],
    },
    {
        name: 'skull902',
        tagline: 'YouTuber / Voice Actor',
        description: 'Head of the 902 Racing League. Skull902 works towards bringing old sonic racing titles back into the public eye and competitive scene.',
        profile: skull92,
        socials: [
            {
                type: 'twitter',
                url: 'https://twitter.com/skull902',
            },
            {
                type: 'youtube',
                url: 'https://www.youtube.com/@skull902',
            },
        ],
    },
    {
        name: 'SparkStar',
        tagline: 'Sonic Rush Enthusiast / VTuber',
        description: 'Spark tries to be the best streamer he can. Diving into Sonic, Kingdom Hearts, Dragonball and more, this guy is sure to put a smile on your face.',
        profile: spark,
        socials: [
            {
                type: 'twitter',
                url: 'https://twitter.com/realsparkstar',
            },
            {
                type: 'twitch',
                url: 'https://www.twitch.tv/sparkstarvt',
            },
            {
                type: 'youtube',
                url: 'https://www.youtube.com/@sparkstarvt',
            },
        ],
    },
    {
        name: 'Swolekat',
        tagline: 'Tech Buff / VTuber',
        description: 'Swole enjoys games old games on old machines and cares way too much about streamer tech.',
        profile: swolekat,
        socials: [
            {
                type: 'twitter',
                url: 'https://twitter.com/swolekat1',
            },
            {
                type: 'twitch',
                url: 'https://www.twitch.tv/swolekat',
            },
            {
                type: 'youtube',
                url: 'https://www.youtube.com/@swolekat_',
            },
        ],
    },
    {
        name: 'Tetra Kanyx',
        tagline: 'SRB2K Archivist / VTuber',
        description: 'Tetra is a prolific racer in both SRB2K and Ring Racers. But she also runs the SRB2K map museum, a server with a treasure trove of old maps that must be experienced!' ,
        profile: tetrakaynx,
        socials: [
            {
                type: 'twitter',
                url: 'https://twitter.com/TetraKanyx',
            },
            {
                type: 'twitch',
                url: 'https://www.twitch.tv/tetrakanyx',
            },
        ],
    },
    {
        name: 'TransCanadaLimited',
        tagline: 'Railfan / VTuber',
        description: 'Mocha\'s life is on the raceway and she\'s making her world tour in gaming. Doesn\'t matter if it\'s by train or rallycar she\'s coming to a screen near you!' ,
        profile: tcl,
        socials: [
            {
                type: 'twitter',
                url: 'https://twitter.com/rallyrabbit94',
            },
            {
                type: 'youtube',
                url: 'https://www.youtube.com/c/TransCanadaLimited',
            },
        ],
    },
    {
        name: 'ZinnyFinity',
        tagline: 'Artist / VTuber',
        description: 'Zinny is all about helping his friends grow their community: be it through art or streams!',
        profile: zinny,
        socials: [
            {
                type: 'twitter',
                url: 'https://twitter.com/ZinnyFinity',
            },
            {
                type: 'twitch',
                url: 'https://www.twitch.tv/zinnyfinity',
            },
        ],
    },
    {
        name: 'Zonic',
        tagline: 'Horror Fan / VTuber',
        description: 'Zonic enjoys messing with multiple models and loves to play just about anything!',
        profile: zonic,
        socials: [
            {
                type: 'twitter',
                url: 'https://twitter.com/ZachandZonic',
            },
            {
                type: 'twitch',
                url: 'https://www.twitch.tv/zachandzonic',
            },
            {
                type: 'youtube',
                url: 'https://www.youtube.com/user/ZonicTHedgehog',
            },
        ],
    },
];