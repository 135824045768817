import {useEffect, useState} from 'react';
import {Outlet} from "react-router-dom";
import './prototype-layout.css';
import {PrototypeSidebar} from "./prototype-sidebar";
import {PrototypeProvider} from "../prototype-context";
import gkThreat from './gk_threat.png';

export const PrototypeLayout = () => {
    const [showThreat, setShowThreat] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setShowThreat(true);
            setTimeout(() => {
                setShowThreat(false);
            }, 3000);
        }, (Math.round(Math.random() * 120000)) + 60000);
    }, []);
    return (
        <div className="prototype-page">
            <PrototypeProvider>
                <div className='prototype-sidebar-wrapper'>
                    <PrototypeSidebar />
                </div>
                <div className='prototype-main-content'>
                    <Outlet />
                </div>
            </PrototypeProvider>
            {
                showThreat && (
                    <img src={gkThreat} alt='threat' className='threat' />
                )
            }
        </div>
    )
};