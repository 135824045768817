import styles from './guests.module.css'
import {PageTitle} from "../components/page-title";
import {EndingLogo} from "../components/ending-logo";
import {GuestList} from "./guest-list";
import {Guest} from "./guest";
import {AndMore} from "../components/and-more";

export const Foa2024Guests = () => {
    return (
        <div className={styles['guests-page']}>
            <PageTitle text='Featured Guests for FoA 2024' />
            <div className={styles['guests-list']}>
                {
                    GuestList.map((guest) => (
                        <Guest guest={guest} key={guest.name} />
                    ))
                }
            </div>
            <AndMore />
            <EndingLogo />
        </div>
    );
};