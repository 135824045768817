import {SectionHeader} from "../section-header";
import {Video} from "./video";
import './video-list.css';

export const VideoList = ({title, videos, isSmall}) => {
    if(!videos || videos.length === 0){
        return null;
    }
    return (
        <div className='video-list'>
            <SectionHeader isSmall={isSmall} text={title}/>
            <div className='video-list-videos'>
                {videos.map((v, index) => (<Video key={v.url} video={v} isBig={index % 5 === 0} />))}
            </div>
        </div>
    )
};