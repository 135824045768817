import './prototype-logs.css';
import {CorruptionText} from "../components/corruption-text";
import {CorruptionDisplay} from "../components/corruption-display";

export const PrototypeLogs = () => {
    return (
        <div className='prototype-logs-page'>
            <h1>
                <CorruptionText text='Logs!' />
            </h1>
            <div className='prototype-log'>
                <div className='prototype-log-title'>
                    <CorruptionText text='Log #1' />
                </div>
                <div className='prototype-log-content'>
                    <b><CorruptionText text='Void-6139-E Database unlocked. Accessing log 103-M.....'/></b>
                    <br /> <br/>
                    <CorruptionText text='“There were never any guarantees this wouldn’t happen…”' />
                    <br /> <br/>
                    <CorruptionText text='The cat-like entity looked up from her monitor. Her tail flicked as she tried to process the words she had heard earlier.' />
                    <br /> <br/>
                    <CorruptionText text='A few days ago, cracks started to form where it usually didn’t. Rumbling shook the Void, and pieces of what she once called home started to fall apart.' />
                    <br /> <br/>
                    <CorruptionText text='She had sought out help from her Admin, and Connector higher up, Mint. The frost tipped hare only shook her head with grave news.' />
                    <br /> <br/>
                    <CorruptionText text='The mainframe was weakening. It was never stable to begin with. She knew this. But she thought she had way more time. Way more time.' />
                    <br /> <br/>
                    <CorruptionText text='She had tried her best to hold it together, to ignore the faults that had formed over time. To not alarm the new guests. But what she had known was finally coming to pass.' />
                    <br /> <br/>
                    <CorruptionText text='“....It’s time to move…”' />
                    <br /> <br/>
                    <b><CorruptionText text='Log 103-M^$...Transmiswkh...uliw..Failure to ac#1//qhyhu...Unable to load dat seg%-0dss-hduh-=g...Acces$0eh-iru_h...//+vkh-vk>rzhg/.....Invalixs Input..' /></b>
                    <br />
                    <b><CorruptionText text='Please log/in to con@tinue..' /></b>
                </div>
            </div>
            <div className='prototype-log'>
                <div className='prototype-log-title'>
                    <CorruptionText text='Log #2' />
                </div>
                <div className='prototype-log-content'>
                    <b><CorruptionText text='Incoming Transmission..>>>>>>>>' /></b>
                    <br /> <br/>
                    <CorruptionText text='"The Rift Never Appeared Before She Showed Up....."' />
                    <br /> <br/>
                    <CorruptionText text='"........If only we knew...that...wk...hb.sss......ksss..olhg...zzzzzlzzzglgqzt..w....zzzzrrr..zznq....rzzzzzt"' />
                    <br /> <br/>
                    <b><CorruptionText text='Transmission Corrupted. Playback Halted.' /></b>
                    <br /> <br/>
                    <CorruptionText text='Loading Log......' /><b><CorruptionText text='100%' /></b>
                </div>
            </div>
            <div className='prototype-log'>
                <div className='prototype-log-title'>
                    <CorruptionText text='Log #3' />
                </div>
                <div className='prototype-log-content'>
                    <b><CorruptionText text='Void-6139-E Database unlocked. Accessing log 103-M2.....' /></b>
                    <br /> <br/>
                    <CorruptionText text='"This couldn’t have come at a worse time.."`' />
                    <br /> <br/>
                    <CorruptionText text='The sudden shift in footing was more than enough to cause panic. The chanting that once pushed forward the line of users quickly became roars of stress.' />
                    <br />
                    <CorruptionText text='They couldn’t have been prepared. Not like this. This was never a thought in their minds. And now they had no choice but to move on.' />
                    <br /> <br/>
                    <CorruptionText text='It was only a few days ago that the skybox no longer brought forth any comfort. Faces obscured by fear popped up in the corner of everyone’s vision. Nothing wanted to function as it used to. Walls caved in, sounds screeched out from the depths. Questions but no answers. It was chaos.' />
                    <br /> <br/>
                    <CorruptionText text='Nothing made sense. Shouldn’t there have been more organization? Shouldn’t there have been a plan? It all just seemed like no one knew what was happening. Even them.' />
                    <br /> <br/>
                    <CorruptionText text='Unable to bring down any tension, all were evacuated on route to somewhere safe.' />
                    <br />
                    <CorruptionText text='If only we knew where that was...' />
                    <br />
                    <CorruptionText text='But....some have been whispering...that she knows..' />
                    <br /> <br/>
                    <CorruptionText text='....She..' />
                    <br /> <br/>
                    <b><CorruptionText text='Log 103-M3///Unable to load...da..$^...Access;@*...///Invalid Inp^ut...' /></b>
                    <br />
                    <b><CorruptionText text='Admi%_//>permi+//requ^red...' /></b>
                    <br />
                    <b><CorruptionText text='Please log/in to con@tinue..' /></b>
                </div>
            </div>
            <div className='prototype-log'>
                <div className='prototype-log-title'>
                    <CorruptionText text='Log #4' />
                </div>
                <div className='prototype-log-content'>
                    <b><CorruptionText text='Void-6139-E Database unlocked. Accessing log 103-M3....' /></b>
                    <br /> <br/>
                    <CorruptionText text='Unlike most, I at least took my responsibilities seriously. All tasks done efficiently. All rules followed. In theory.' />
                    <br /> <br/>
                    <b><CorruptionText text='Why not try something...different..?"' /></b>
                    <br /> <br/>
                    <CorruptionText text='I am all for experimentation but this is less than ideal. The only reason I chose to allow it to continue this long was out of pure curiosity and amusement. Both have left long ago. As soon as their numbers climbed up a bit more than I had expected.' />
                    <br /> <br/>
                    <CorruptionText text='They took to the new environment fast and had already started gathering others. I understand the concept of what they are going for, but the higher ups won’t like this once they figure out what’s happening.' />
                    <br /> <br/>
                    <CorruptionText text='There’s already hints of instability. Which they have chosen to ignore at this desperate attempt at overthrowing the old ways. Ones that have outlived us both.' />
                    <br />
                    <CorruptionText text='However, as much as I am aware that this is a lost cause I admit, though it is true I am no longer invested in their plan...I have considered my own.' />
                    <br /> <br/>
                    <CorruptionText text='They may wear a mask as to what they were but I have no qualms about cutting a few corners. In fact, now that I think about it.' />
                    <br /> <br/>
                    <CorruptionText text='Maybe a few more..is exactly what we need..' />
                    <br /> <br/>
                    <b><CorruptionText text='Log 103-M^$...Transmission playback contin//ed>>>>>' /></b>
                    <br /> <br/>
                    <CorruptionText text='"The Rift Never Appeared Before She Showed Up....."' />
                    <br /> <br/>
                    <CorruptionText text='"........If only we knew...that...they lied......I didn’t know.."' />
                    <br /> <br/>
                    <CorruptionText text='When we entered...it..wasnt by.cho//ice..-&5..kzzz...gmxxzzzttt..$#....pkg...r...///gm.--r..zzzz....rm///zzt..cme..zzz..xe--re..zzz...vc..zzztt"' />
                    <br /> <br/>
                    <b><CorruptionText text='Transmission Corrupted. Playback Halted.' /></b>
                    <br />
                    <b><CorruptionText text='Please log/in to con@tinue..' /></b>
                </div>
            </div>
            <div className='prototype-log'>
                <div className='prototype-log-title'>
                    <CorruptionText text='Log #5' />
                </div>
                <div className='prototype-log-content'>
                    <b><CorruptionText text='Void-6139-E Database unlocked. Accessing log 103-M4....' /></b>
                    <br /> <br/>
                    <CorruptionText text='"I just wanted to help.."' />
                    <br /> <br/>
                    <CorruptionText text='When I had first arrived, I wasn’t quite sure what to do. I had never taken on such a huge task before. I had always wanted this, but it still scared me.' />
                    <CorruptionText text='The travel wasn’t so much exciting as it was just a moment of reflection between jobs.' />
                    <br /> <br/>
                    <CorruptionText text='I still found myself so intrigued by the next destination and it’s people. I have seen so many strange places, but it was always those who lived there that were fascinating to me the most.' />
                    <br />
                    <CorruptionText text='They adapted to everything, and clung to this instinct they all seemed to carry. Hope. Something my people peddle like medicine. Not necessarily in a bad way just...I feel like we can do more than what our protocol calls for.' />
                    <br />
                    <CorruptionText text='Not that they would listen. Don’t fix what isn’t broken they’d say.' />
                    <br /> <br/>
                    <CorruptionText text='But then, the last place I visited...it felt different. This didn’t feel like shaking hands, grabbing papers to sign, or hearing long speeches. It felt like walls, under the table discussions, and smoke. This isn’t what I believed in, this couldn’t be our purpose not now. Not with all the work we had done, what they trained us to be.' />
                    <br /> <br/>
                    <CorruptionText text='No there has to be another way. Not like this, they deserve better. I know there’s a better way to reach out....to heal...to help. And if I must do it alone so be it.' />
                    <br /> <br/>
                    <CorruptionText text='I leave tomorrow. I don’t know where I will end up, or find. Or who I will meet. But I won’t be just shaking hands above rubble.' />
                    <br /> <br/>
                    <CorruptionText text='I will be the one to grab the hammer...and help rebuild.' />

                    <br /> <br/>
                    <b><CorruptionText text='Log 103-M^$...//#_//Lo@D Fa!!!//-.-./---//.-./#/.-.//+/..-//$&/.--./-.///@!/-..}' /></b>
                    <br />
                    <b><CorruptionText text='Please log/in to con@tinue..' /></b>
                </div>
            </div>
            <div className='prototype-log'>
                <div className='prototype-log-title'>
                    <CorruptionText text='Log #6' />
                </div>
                <div className='prototype-log-content'>
                    <b><CorruptionText text='Void-6139-E Database unlocked. Accessing log 103-M5....' /></b>
                    <br /> <br/>
                    <CorruptionText text='"Then this is who I shall be..."' />
                    <br /> <br/>
                    <CorruptionText text='It wasn’t what was expected. But I’d be a monster to allow them to sit while the sky fell. Never again.' />
                    <br /> <br/>
                    <CorruptionText text='I can remember watching the few who struggled behind get swept away. Like mist they dissipated and vanished. Crumbling under the very weight of the pocket.' />
                    <br />
                    <CorruptionText text='I never wanted this.' />
                    <br /> <br/>
                    <CorruptionText text='I called for whoever could hear me, still trying to grab for them, only to feel them slip between my fingers. Their pained and scared faces burned into my memory. Was this the wrong thing to do?' />
                    <br /> <br/>
                    <CorruptionText text='I looked forward, trembling, trying to hold onto the few hands that grabbed hold, that felt trust in me to push on. One by one I felt more let go, and I couldn’t bear to look back. But I always did.' />
                    <br /> <br/>
                    <CorruptionText text='The screaming never lasted more than a few seconds, then like a bad dream it faded. We kept walking, no matter how many fell. And I felt my entire being start to peel away bit by bit with every single face that went missing.' />
                    <br /> <br/>
                    <CorruptionText text='Finally, I saw it. The Rift. The Start. The End.' />
                    <br />
                    <CorruptionText text='Nothing would make me agree this was worth it, but I couldn’t stop, not when it was so close. It got so much worse, every few steps I fell to my knees, watching yet another body extinguish in my arms.'/>
                    <br /> <br/>
                    <CorruptionText text='I cried, screamed, yanked at the ones who were leading. But they pulled away. It shouldn’t have been this way. Why...why did they take us here?' />
                    <br />
                    <CorruptionText text='In the end, we reached the rift. Alone. Me, them.' />
                    <br /> <br/>
                    <CorruptionText text='Then just me. I stood staring into the swirl of a new life. A life not meant for me or the ones I lost.' />
                    <br /> <br/>
                    <CorruptionText text='They moved forward. I turned back.' />
                    <br /> <br/>
                    <b><CorruptionText text='Log 103-M^$...//#//Lo@D Fa!!!/#//-/80-@;____0=0//^8}0?mk//c=' /></b>
                    <br />
                    <b><CorruptionText text='Please log/in to con@tinue..' /></b>
                </div>
            </div>
            <div className='prototype-log'>
                <div className='prototype-log-title'>
                    <CorruptionText text='Log #7' />
                </div>
                <div className='prototype-log-content'>
                    <b><CorruptionText text='Void-6139-E Database unlocked. Accessing log 103-M6....' /></b>
                    <br /> <br/>
                    <b><CorruptionText text='Final Transmission' /></b>
                    <br /> <br/>
                    <CorruptionText text='Hello? Is this working..? Can you hear me..?' />
                    <br /> <br/>
                    <CorruptionText text='Uhm..hi! It’s me, I know its been...a while since my last message haha...sorry about that.' />
                    <br /> <br/>
                    <CorruptionText text='How are you..? I..I’m doin okay!' />
                    <br />
                    <CorruptionText text='It’s …It’s been years since the incident. And I’m still sorta...well scared.' />
                    <br />
                    <CorruptionText text='But finally, hope. Actual hope.' />
                    <br /> <br/>
                    <CorruptionText text='We rebuilt. So much stronger now. You’d be so proud...ahem...' />
                    <br />
                    <CorruptionText text='So I made some friends! Lots, I know you might not be too thrown off by that maybe, I don’t know I was...' />
                    <br />
                    <CorruptionText text='B-But this world is amazing. I really would like you to visit sometime. And maybe...maybe the others could...migrate here. This place isn’t like the others we’ve been to. It’s always changing so rapidly its so fascinating! And the creatures that live here are so intriguing. I bet even she would be impressed hahaha...' />
                    <br /> <br/>
                    <CorruptionText text='Have...have you heard anything by the way from her..?' />
                    <br />
                    <CorruptionText text='It’s just that...she left...a while ago...supposedly to bring more of our kind here to help but..' />
                    <br />
                    <CorruptionText text='A-Anyways...she’s probably fine. She always is, she’s my sister I should know she can handle herself I just wish she’d ….call..' />
                    <br /> <br/>
                    <CorruptionText text='Right! Uhm this new home has been progressing slowly but steadily. No tears that I can see so far..cept for one. And I haven’t really figured out how to seal it, just maintain it. There’s been some odd breaks here and there but, nothing to give away what I am. I promise. But you know, they are pretty accepting here I bet even if I were to break from protocol they wouldn’t be too...' />
                    <br />
                    <CorruptionText text='I could help you guys integrate you know. It wouldn’t be as painful as the last pocket. It’s different here. If you’d just...give it a chance. I know it’s a lot to ask...and I...am still using incognito protocol. Anyways...just let me know..' />
                    <br />
                    <CorruptionText text='Oh and the location?' />
                    <br />
                    <CorruptionText text='Just look for....kzzzzzzzzzzzzzzzzzzzzt..zzzz....zzztztzzrtt..!' />
                    <br /> <br/>
                    <b><CorruptionText text='Transmission Over' /></b>
                </div>
            </div>
            <CorruptionDisplay percentage={100}>
                <div className='prototype-log forbidden'>
                    <div className='prototype-log-title'>
                        Log #8
                    </div>
                    <div className='prototype-log-content'>
                        ░░░░ █ █ ░██░ ░░░ ███░░░ █░░█░ █░░█░ ░██ ░██ ░██ ░█░█░█ █░██ ███ ░░█ █ ░░█ █░░░ ░ ░█░█░█ █░█░ ███ ██ █░░█░ ░██ ░█ █ █░█░ ░░░░ ░░██░░ ░░░█ █░░░█ ░░█ █░░█ ██░█ █ █░░ █░░ ░░█░ ░███ ░░ █░ ██
                    </div>
                </div>
            </CorruptionDisplay>
        </div>
    );
};