import {usePrototypeContext} from "../prototype-context";

export const CorruptionText = ({text}) => {
    const {corruptionPercentage} = usePrototypeContext();
    let textToDisplay = text;
    if(corruptionPercentage >= 10){
        textToDisplay = textToDisplay.replace(/e/g, '3').replace(/E/g, '3');
    }
    if(corruptionPercentage >= 20){
        textToDisplay = textToDisplay.replace(/a/g, '4').replace(/A/g, '4');
    }
    if(corruptionPercentage >= 20){
        textToDisplay = textToDisplay.replace(/a/g, '4').replace(/A/g, '4');
    }
    if(corruptionPercentage >= 30){
        textToDisplay = textToDisplay.replace(/o/g, '0').replace(/O/g, '0');
    }
    if(corruptionPercentage >= 40){
        textToDisplay = textToDisplay.replace(/g/g, '6').replace(/G/g, '8');
    }
    if(corruptionPercentage >= 50){
        textToDisplay = textToDisplay.replace(/t/g, '7').replace(/T/g, '7');
    }
    if(corruptionPercentage >= 60){
        textToDisplay = textToDisplay.replace(/u/g, '|_|').replace(/U/g, '|_|');
    }
    if(corruptionPercentage >= 70){
        textToDisplay = textToDisplay.toLowerCase();
    }
    if(corruptionPercentage >= 80){
        textToDisplay = textToDisplay.replace(/s/g, '5').replace(/S/g, '5').replace(/i/g, '1').replace(/I/g, '1');
    }
    if(corruptionPercentage >= 90){
        textToDisplay = textToDisplay.replace(/(.)./g, '░');
    }
    if(corruptionPercentage >= 100){
        textToDisplay = textToDisplay.replace(/./g, '█');
    }
    return (
        <>
            {textToDisplay}
        </>
    )
};