import './charachter-bio.css';

export const CharachterBio = ({name, charachterInfo, isp1}) => {
    const {style, nationality, likes, funFact} = charachterInfo;


    return (
        <div className={`charachter-info ${isp1 ? 'player1' : 'player2'}`} key={name}>
            <div className='charachter-name'>
                {name}
            </div>
            <div className='charachter-details'>
                <div className='details-item'>
                    <div className='details-header'>
                        Fighting Style
                    </div>
                    <div className='details-content'>
                        {style}
                    </div>
                </div>
                <div className='details-item'>
                    <div className='details-header'>
                        Nationality
                    </div>
                    <div className='details-content'>
                        {nationality}
                    </div>
                </div>
                <div className='details-item'>
                    <div className='details-header'>
                        Likes
                    </div>
                    <div className='details-content'>
                        {likes}
                    </div>
                </div>
                <div className='details-item'>
                    <div className='details-header'>
                        Fun Fact
                    </div>
                    <div className='details-content'>
                        {funFact}
                    </div>
                </div>
            </div>
        </div>
    )
};