import {CorruptionDisplay} from '../components/corruption-display';
import './prototype-about.css';
import banner from './under construction.gif';
import catconstruction from './catconstruction.gif';
import rightcat from './rightcat.gif';
import chile from './chilec.gif';
import girlCat from './girl-cat.gif';
import petco from './really_cute_cat_add.gif';
import dant from './buttons/dant.gif';
import gk from './buttons/gkb.gif';
import pont from './buttons/pont.gif';
import firefox from './buttons/firefoxnow.gif';
import neogeo from './buttons/neo-geo.gif';
import newgrounds from './buttons/newgrounds.gif';
import secondlife from './buttons/secondlife.gif';
import anythingbut from './buttons/anythingbut.gif';
import thispageisa from './buttons/aoltos_a.gif';
import bestviewedwith from './buttons/best_viewed_with_monitor.gif';
import cococade from './buttons/coco_cade.gif';
import doom from './buttons/doomrl.gif';
import dreamcast from './buttons/dreamcast.gif';
import hasmile from './buttons/hasmile.gif';
import javass from './buttons/javass.gif';
import keep from './buttons/keep.gif';
import netscapeexplorer from './buttons/netscapeexplorer.gif';
import nofuckingthanks from './buttons/nofuckingthanks.gif';
import rainbow from './buttons/rainbow_bev.gif';
import rarnow from './buttons/rarnow.gif';
import nonuplex from './nonupleX.webp';

export const PrototypeAbout = () => {
    return (
        <div className='prototype-about-page'>
            <div className='prototype-about-page-content'>
                <img className='about-banner' src={banner} alt='under construction' />
                <div className='prototype-about-title'>
                    About this web page!
                </div>
                <p>
                    <img className='castruction' src={catconstruction} alt='its really under construction' /> Sorry for the mess! Our gatos are working vewwy hard to fix it :3 I wanted to put this page out so that everyone could see how cool the Invalid Gateway is going to be!
                    Wanna see my emoji collection? I bet you do! ≽^•⩊•^≼  /ᐠ - ˕ -マ ᓚ₍ ^. .^₎ ฅ^•ﻌ•^ฅ ≽^- ˕ -^≼ ฅ^._.^ฅ =^◕⩊◕^=
                </p>
                <img className='prototype-about-divider' src={rightcat} alt='' />
                <div className='prototype-about-subtitle'>
                    Sponsors
                </div>
                <div className='prototype-about-sponsors'>
                    <img src={chile} alt='the entire chilean government' />
                    <img src={girlCat} alt='anime waifus' />
                    <img src={petco} alt='grocery store' />
                    <CorruptionDisplay percentage={100}>
                        <img src={nonuplex} alt='xxxxxxxxx' />
                    </CorruptionDisplay>
                </div>
                <img className='prototype-about-divider' src={rightcat} alt='' />
                <div className='prototype-about-subtitle'>
                    Friends + Links
                </div>
                <div className='prototype-about-friends'>
                    <a href='https://dawnatdusk.neocities.org/'>
                        <img src={dant} alt='ms. manager!' />
                    </a>
                    <a href='https://www.twitch.tv/glitch_kitten'>
                        <img src={gk} alt='my webcasts!' />
                    </a>
                    <a href='https://pont.cool/'>
                        <img src={pont} alt='ponteroni' />
                    </a>
                    <a href='https://www.mozilla.org/en-US/firefox/new/'>
                        <img src={firefox} alt='show me the firefax' />
                    </a>
                    <a href='https://neo-geo.com/'>
                        <img src={neogeo} alt='neo geo neo geo' />
                    </a>
                    <a href='https://www.newgrounds.com/'>
                        <img src={newgrounds} alt='newgrounds' />
                    </a>
                    <a href='https://secondlife.com//'>
                        <img src={secondlife} alt='secondlife' />
                    </a>
                    <img src={anythingbut} alt='anything but chrome' />
                    <img src={thispageisa} alt='this page is a' />
                    <img src={bestviewedwith} alt='best viewed with' />
                    <img src={cococade} alt='coco cade' />
                    <img src={doom} alt='doom' />
                    <img src={dreamcast} alt='dreamcast' />
                    <img src={hasmile} alt='have a smile' />
                    <img src={javass} alt='need javascripts' />
                    <img src={keep} alt='keep the web free' />
                    <img src={netscapeexplorer} alt='netscape explorer' />
                    <img src={nofuckingthanks} alt='no fucking thanks' />
                    <img src={rainbow} alt='rainbow' />
                    <img src={rarnow} alt='rar now' />
                </div>
            </div>
        </div>
    );
};