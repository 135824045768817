import {Outlet} from "react-router-dom";
import styles from './foa2024-layout.module.css';
import {Header} from "./header";

export const Foa2024Layout = () => {
    return (
        <div className={styles['layout']}>
            <div className={styles.header}>
                <Header />
            </div>
            <div className={styles.content}>
                <Outlet />
            </div>
        </div>
    );
};