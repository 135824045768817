import './prototype-landing.css';
import gkCube from './gkcube.gif';
import walkingCat from './catwalkingani.gif';
import catBlink from './CatEyesBlink.gif';
import catEmail from './email.gif';

export const PrototypeLanding = () => {
    return (
        <div className='prototype-landing-page'>
            <div className='prototype-landing-page-content'>
                <div className='landing-stream-add'>
                    If you'd like the chance to watch my Broadcasts live... Click <a href='https://www.twitch.tv/glitch_kitten'>here!</a>
                </div>
                <div className='main-landing-content'>
                    <div className='landing-banner'>
                        <img className='landing-gk-cube' src={gkCube} alt='she knows all'/>
                        <div>
                            Invalid Gateway Zone
                        </div>
                        <img className='landing-gk-cube' src={gkCube} alt='she knows all'/>
                    </div>
                    <p>
                        Welcome to my page! I don't really know what to do. This webpage will get better. In this site you will find some cool things (things under construction), links to cool sites, and some totally normal stuff, Enjoy!.
                        Have a browse and don't hesitate to let me know what you think of the place.
                    </p>
                    <p>
                        <i>This site is best viewed under the influence of:</i>
                    </p>
                    <img className='walking-cat' src={walkingCat} alt='a cat walking' />
                    <p>
                        If you study the material on this website you will hopefully understand what our purpose here on earth has been.
                    </p>
                    <p>
                        This website is powered by: glitches, cats, hopes, dreams, and more.
                    </p>
                    <div className='email-banner'>
                        <img src={catEmail} alt='the cat do be emailing' />
                        <div>
                            Got a tip for us? Send it to our anonymous, top-secret tip line: tips@invalidgateway.com
                        </div>
                        <img src={catEmail} alt='the cat do be emailing' />
                    </div>
                    <div className='landing-footer'>
                        ©1996 Invalid Gateway
                    </div>
                    <img src={catBlink} alt='our legal team is watching' />
                </div>
            </div>
        </div>
    );
};