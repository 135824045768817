import {NavLink} from "react-router-dom";
import './misc-landing.css';

export const MiscLanding = () => {
    return (
        <div className='misc-landing-page'>
            <h1>Miscellaneous pages</h1>
            <ul>
                <li>
                    <NavLink to="/misc/fighting-game">
                        Fighting Game Intro tool
                    </NavLink>
                </li>
            </ul>
        </div>
    );
};