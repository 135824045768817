import gkSelect from './assets/select/gk.png';
import gkP1 from './assets/portraits/gkP1.png';
import gkP2 from './assets/portraits/gkP2.png';

import rockSelect from './assets/select/rock.png';
import rockP1 from './assets/portraits/rockP1.png';
import rockP2 from './assets/portraits/rockP2.png';

import spadeSelect from './assets/select/spade.png';
import spadeP1 from './assets/portraits/spadeP1.png';
import spadeP2 from './assets/portraits/spadeP2.png';

import azzySelect from './assets/select/azzy.png';
import azzyP1 from './assets/portraits/azzyP1.png';
import azzyP2 from './assets/portraits/azzyP2.png';

import jackieSelect from './assets/select/jackie.png';
import jackieP1 from './assets/portraits/jackieP1.png';
import jackieP2 from './assets/portraits/jackieP2.png';

import mochaSelect from './assets/select/mocha.png';
import mochaP1 from './assets/portraits/mochaP1.png';
import mochaP2 from './assets/portraits/mochaP2.png';

import astroSelect from './assets/select/astro.png';
import astroP1 from './assets/portraits/astroP1.png';
import astroP2 from './assets/portraits/astroP2.png';

import sparkSelect from './assets/select/spark.png';
import sparkP1 from './assets/portraits/spark.png';

import swoleSelect from './assets/select/swole.png';
import swoleP1 from './assets/portraits/swoleP1.png';
import swoleP2 from './assets/portraits/swoleP2.png';

import spySelect from './assets/select/spy.png';
import spyP1 from './assets/portraits/spy.png';

import randomSelect from './assets/select/random.png';
import randomP1 from './assets/portraits/random.png';

export const randomCharacter = {
    name: 'Random',
    position: {
        row: 1,
        column: 5,
    },
    assets: {
        selectPortrait: randomSelect,
        p1Portrait: randomP1,
        p2Portrait: randomP1,
    },
    info: {
        style: '???',
        nationality: '???',
        likes: '???',
        funFact: '???'
    }
};

export const characterInfo = [
    {
        name: 'Glitch_Kitten',
        position: {
            row: 0,
            column: 4,
        },
        assets: {
            selectPortrait: gkSelect,
            p1Portrait: gkP1,
            p2Portrait: gkP2,
        },
        info: {
            style: 'Scratches and Vibes',
            nationality: 'Void',
            likes: 'Coffee, Music',
            funFact: 'Enamel Pin addict'
        }
    },
    {
        name: 'Rockawaycarter',
        position: {
            row: 0,
            column: 5,
        },
        assets: {
            selectPortrait: rockSelect,
            p1Portrait: rockP1,
            p2Portrait: rockP2,
        },
        info: {
            style: 'ShaqFu',
            nationality: 'USA',
            likes: 'Boyfriend, Fighting Games',
            funFact: 'Harmonica maestro'
        }
    },
    {
        name: 'Spade',
        position: {
            row: 0,
            column: 6,
        },
        assets: {
            selectPortrait: spadeSelect,
            p1Portrait: spadeP1,
            p2Portrait: spadeP2,
        },
        info: {
            style: 'Hospitality',
            nationality: 'Canada',
            likes: 'Yakuza, Tetris DS',
            funFact: 'Makes music mashups for fun'
        }
    },
    {
        name: 'Azurinefox',
        position: {
            row: 1,
            column: 3,
        },
        assets: {
            selectPortrait: azzySelect,
            p1Portrait: azzyP1,
            p2Portrait: azzyP2,
        },
        info: {
            style: 'Random Bullshit GO!',
            nationality: 'Ravendell',
            likes: 'Nature Walks, Sweet Cakes, Hollyberry Decorations',
            funFact: 'Not here for a long time, just here for a good time'
        }
    },
    {
        name: 'MidKitZ',
        position: {
            row: 1,
            column: 4,
        },
        assets: {
            selectPortrait: jackieSelect,
            p1Portrait: jackieP1,
            p2Portrait: jackieP2,
        },
        info: {
            style: 'Transit Martial Arts',
            nationality: 'USA',
            likes: 'Food, Machines, Music, Travel, Art',
            funFact: 'Really likes fishing'
        }
    },
    randomCharacter,
    {
        name: 'Mocha',
        position: {
            row: 1,
            column: 6,
        },
        assets: {
            selectPortrait: mochaSelect,
            p1Portrait: mochaP1,
            p2Portrait: mochaP2,
        },
        info: {
            style: 'Zoom and BOOM',
            nationality: 'Leaf Forrest, Mobius',
            likes: 'Rallysport, Railways, Roller Coasters',
            funFact: 'Secretly a major gun nut'
        }
    },
    {
        name: 'AstrowellTV',
        position: {
            row: 1,
            column: 7,
        },
        assets: {
            selectPortrait: astroSelect,
            p1Portrait: astroP1,
            p2Portrait: astroP2,
        },
        info: {
            style: 'Chaos',
            nationality: 'Chilean?',
            likes: 'Space, Videogames, Music, Animals, Social Things, Old Electronics',
            funFact: 'Originally made of goop'
        }
    },
    {
        name: 'SparkStarVT',
        position: {
            row: 2,
            column: 4,
        },
        assets: {
            selectPortrait: sparkSelect,
            p1Portrait: sparkP1,
            p2Portrait: sparkP1,
            onePortrait: true,
        },
        info: {
            style: 'Mimicfu',
            nationality: 'Reaches Unknown',
            likes: 'Sonic Rush, Philly Cheesesteaks, Stars',
            funFact: 'Accidentally created an extra lifeline on his right hand'
        }
    },
    {
        name: 'Swolekat',
        position: {
            row: 2,
            column: 5,
        },
        assets: {
            selectPortrait: swoleSelect,
            p1Portrait: swoleP1,
            p2Portrait: swoleP2,
        },
        info: {
            style: 'Lift Fu',
            nationality: 'USA',
            likes: 'Anime Tiddies',
            funFact: 'House is full of Oreos'
        }
    },
    {
        name: 'Nyte',
        position: {
            row: 2,
            column: 6,
        },
        assets: {
            selectPortrait: spySelect,
            p1Portrait: spyP1,
            p2Portrait: spyP1,
            onePortrait: true,
        },
        info: {
            style: 'Unknown',
            nationality: 'Parts Unknown',
            likes: 'Lasers, Explosions',
            funFact: 'You\'re not seeing double'
        }
    },
];