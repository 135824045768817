import {useCallback} from 'react';

import {useMemberFromMemberId} from "../../../../hooks/use-member-from-member-id";
import {useLiveFromMemberId} from "../../../../hooks/use-live-from-member-id";
import {useTeamContext} from "../../team-context";

import './team-member.css';

export const TeamMember = ({memberId}) => {
    const {selectedMember, setSelectedMember} = useTeamContext();
    const {profilePicture, name} = useMemberFromMemberId(memberId);
    const isLive = useLiveFromMemberId(memberId);

    const onClick = useCallback(() => {
        setSelectedMember(memberId, name);
    }, [memberId, name, setSelectedMember]);
    return (
        <button className={`team-member ${selectedMember === memberId ? 'selected' : '' }`} onClick={onClick}>
            <img src={profilePicture} alt={name} />
            <div className={`live-notification ${isLive ? 'live' : ''}`}>
                {isLive ? 'On Air' : 'Offline'}
            </div>
        </button>
    )
};