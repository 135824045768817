import {characterInfo} from "./character-info";

const getCharacterForPosition = (column, row) => {
    return characterInfo.find(c => c.position.column === column && c.position.row === row);
}

export const getCharactersInbetween = (startingCharacter, endingCharacter) => {
    const charactersToTravel = [];
    const desiredPosition = endingCharacter.position;
    const currentPosition = JSON.parse(JSON.stringify(startingCharacter.position));
    while (desiredPosition.column !== currentPosition.column || desiredPosition.row !== currentPosition.row){
       if(desiredPosition.row < currentPosition.row){
           const newRow = currentPosition.row - 1;
           const newChar = getCharacterForPosition(currentPosition.column, newRow);
           if(newChar){
               currentPosition.row = newRow;
               charactersToTravel.push(newChar);
               continue;
           }
       }
        if(desiredPosition.row > currentPosition.row){
            const newRow = currentPosition.row + 1;
            const newChar = getCharacterForPosition(currentPosition.column, newRow);
            if(newChar){
                currentPosition.row = newRow;
                charactersToTravel.push(newChar);
                continue;
            }
        }
        if(desiredPosition.column < currentPosition.column){
            const newColumn = currentPosition.column - 1;
            const newChar = getCharacterForPosition(newColumn, currentPosition.row);
            if(newChar){
                currentPosition.column = newColumn;
                charactersToTravel.push(newChar);
                continue;
            }
        }
        const newColumn = currentPosition.column + 1;
        const newChar = getCharacterForPosition(newColumn, currentPosition.row);
        if(newChar){
            currentPosition.column = newColumn;
            charactersToTravel.push(newChar);
        }
    }

    return charactersToTravel;
};

export const getAVeryLongPath = (startingCharacter, endingCharacter) => {
    let fullPath = [];
    let currentCharacter = startingCharacter;

    for(let x = 0; x < 10; x++){
        const randomCharacter = characterInfo[(Math.round(Math.random() * 1000))%characterInfo.length];
        const smallPath = getCharactersInbetween(currentCharacter, randomCharacter);
        fullPath = [...fullPath, ...smallPath];
        currentCharacter = randomCharacter;
    }
    const lastLeg = getCharactersInbetween(currentCharacter, endingCharacter);
    return [...fullPath, ...lastLeg];
};