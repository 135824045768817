import {VideoList} from "../video-list";
import {useDataContext} from "../../../data-context";
import './team-videos.css';

export const TeamVideos = () => {
    const {allVods, allClips, allVideos} = useDataContext();
    return (
        <div className='team-videos'>
            <VideoList videos={allClips} title='Team Clips' />
            <VideoList isSmall videos={allVideos} title='Our Latest Videos' />
            <VideoList isSmall videos={allVods} title='Full Team Vods' />
        </div>
    );
};