import {createContext, useContext, useMemo} from "react";
import {usePromiseEffect} from "./hooks/use-promise-effect";
import {AltMembers} from "./alt-members";

const DataContext = createContext({});

export const DataProvider = ({ children }) => {
    const otherData = usePromiseEffect(async () => {
        // const response = await fetch(`https://swolekat.github.io/invalid-gateway-website/computed-data/other-data.json?cache=${Date.now()}`);
        const response = await fetch(`/computed-data/other-data.json?cache=${Date.now()}`);
        return response.json();
    }, []);
    const liveData = usePromiseEffect(async () => {
        // const response = await fetch(`https://swolekat.github.io/invalid-gateway-website/computed-data/is-live.json?cache=${Date.now()}`)
        const response = await fetch(`/computed-data/is-live.json?cache=${Date.now()}`)
        return response.json()
    }, []);


    const members = useMemo(() => otherData.value?.members || [], [otherData]);
    const allVods = useMemo(() => otherData.value?.allVods || [], [otherData]);
    const allClips = useMemo(() => otherData.value?.allClips || [], [otherData]);
    const allVideos = useMemo(() => otherData.value?.allVideos || [], [otherData]);

    const value = useMemo(() => ({
        members,
        allVods,
        allClips,
        allVideos,
        liveData: liveData.value,
        altMembers: AltMembers
    }), [members, allVods, allClips, allVideos, liveData]);

    return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};

export const useDataContext = () => {
    return useContext(DataContext);
}