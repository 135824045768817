import puyoscreen from './assets/puyoscreen.jpg';
import puyologo from './assets/puyologo.png';
import puyochar from './assets/puyochar.png';

import ridersscreen from './assets/ridersscreen.webp';
import riderslogo from './assets/riderslogo.webp';
import riderschar from './assets/riderschar.webp';

import jsrscreen from './assets/jsrscreen.png';
import jsrlogo from './assets/jsrlogo.webp';
import jsrchar from './assets/jsrchar.webp';

import rrscreen from './assets/rrscreen.png';
import rrlogo from './assets/rrlogo.png';
import rrchar from './assets/rrchar.png';

import illscreen from './assets/illscreen.png';
import illlogo from './assets/illlogo.png';
import illchar from './assets/illchar.webp';

import tsrscreen from './assets/tsrscreen.webp';
import tsrlogo from './assets/tsrlogo.webp';
import tsrchar from './assets/tsrchar.webp';

export const GamesList = [
    {
        id: 'Puyo Puyo',
        screenshot: puyoscreen,
        logo: puyologo,
        character: puyochar,
        text: 'Originally just a spin off of Compile\'s Madou Monogatari series, this puzzle game has taken the world by storm! Strategically stack blobs known as Puyos to chain combos and outwit your opponent!'
    },
    {
        id: 'Sonic Riders',
        screenshot: ridersscreen,
        logo: riderslogo,
        character: riderschar,
        text: 'Try to keep up with Sonic and the gang in this exciting racing game! Grab your Extreme Gear, take on the Babylon Rouges, and see if you can win it all in the Grand Prix!'
    },
    {
        id: 'Jet Set Radio',
        screenshot: jsrscreen,
        logo: jsrlogo,
        character: jsrchar,
        text: 'Yo! Do you got what it takes to be the best graffiti artist in Tokyo-to? Grab your spray paint and skates because it\'s time to get FUNKY in this certified Dreamcast Classic!'
    },
    {
        id: 'Ring Racers',
        screenshot: rrscreen,
        logo: rrlogo,
        character: rrchar,
        text: 'Dr. Robotnik and Tails are surprising allies in this all new kart-racing game. Perform tricks, use items, do whatever you have to: just make sure you win!'
    },
    {
        id: 'Illbleed',
        screenshot: illscreen,
        logo: illlogo,
        character: illchar,
        text: 'Imagine, if you will, a berserk, B-movie horror-film with a twisted sense of humor who puts up a million bucks (fictitious, of course!) to anyone who can survive his seven movie-theme worlds. Will you win the money make it out alive?'
    },
    {
        id: 'Team Sonic Racing',
        screenshot: tsrscreen,
        logo: tsrlogo,
        character: tsrchar,
        text: 'Gear Up. Speed Up. Team racing at SONIC Speed. Experience high-speed arcade and online competitive team racing across stunning worlds. The ultimate race to victory!'
    },
];