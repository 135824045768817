import styles from './event.module.css';

export const Event = ({event}) => {
    const {
        title,
        titleDescription,
        hostIsPlural,
        hostText,
        hostProfile,
        descriptionImage,
        description,
    } = event;

    return (
        <div className={styles.event}>
            <div className={styles['title-and-host']}>
                <div className={styles['title-section']}>
                    <div className={styles['title']}>
                        {title}
                    </div>
                    <div className={styles['title-description']}>
                        {titleDescription}
                    </div>
                </div>
                <div className={styles['host-section']}>
                    <div className={styles.host}>
                        <i>{hostIsPlural ? 'Hosts:' : 'Host:'}</i> {hostText}
                    </div>
                    <img src={hostProfile} alt={`${hostText}`} />
                </div>
            </div>
            <div className={styles['description-section']}>
                <img src={descriptionImage} alt={`${title} description`} />
                <div className={styles.description}>
                    {description}
                </div>
            </div>
        </div>
    )
};