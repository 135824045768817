import {useState, useCallback} from 'react';
import './spinny-vortex.css';

export const SpinnyVortex = ({theme}) => {
    const [spinFast, setSpinFast] = useState(false);
    const logoClick = useCallback(() => {
        setSpinFast(true);
        setTimeout(() => {
            setSpinFast(false);
        }, 3000);
    }, [setSpinFast]);
    return (
        <div className={`spinny-vortex ${spinFast ? 'fast' : ''} ${theme === 'mobile' ? 'mobile' : ''}`} onClick={logoClick}>
            <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 329 329">
                <g id="Layer_9" data-name="Layer 9">
                    <path className="cls-1"
                          d="M328.01,112.31c-37.47-31.66-84.97-48.93-131.02-62.39,14.26-14.26,28.52-28.52,46.35-38.33-49.02,4.46-93.59,24.96-136.37,48.13-2.96-20.07.47-40.22,5.35-59.72-31.2,37.44-49.91,83.78-62.39,131.02-15.15-12.48-28.52-28.52-38.33-46.35,3.57,49.02,24.07,94.48,48.13,137.26-19.61,0-40.11,0-59.72-6.24,37.44,32.09,84.68,49.91,131.02,63.28-14.26,14.26-29.41,27.63-46.35,38.33,47.97-5.86,95.24-23.07,136.37-49.02,2.67,20.5-.89,41-5.35,60.61,31.2-38.33,49.91-84.68,62.39-131.92,15.15,13.37,27.63,28.52,38.33,46.35-2.84-48.46-24.87-95.16-48.13-136.37,20.5-1.78,40.11,1.78,59.72,5.35ZM233.84,190.35c-8,19-15,39-28,55-3-5-7-9-11-13-19,8-38,16-59,19,1-6,2-11,2-17-19-8-38-15-55-28,2-1,4-2,6-4,1-2,3-4,5-5,1-1,2-2,2-3-8-19-16-38-19-58,6,1,12,2,18,2,5-20,16-38,27-55,4,5,7,9,12,13,19-8,38-16,58-19-1,6-2,11-1,17,8,4,16,7,24,10,11,5,21,12,31,18-5,3-10,6-14,11,8,19,16,38,19,59-6-1-11-2-17-2Z"/>
                </g>
            </svg>
        </div>
    )
};