import {createContext, useContext, useMemo, useCallback, useState} from "react";

const TeamContext = createContext({});

let previousMembers = [];

const checkMemberList = () => {
    if(previousMembers.length < 10){
        return false;
    }
    if(previousMembers.length > 10){
        previousMembers.shift();
    }
    const sortedList = JSON.parse(JSON.stringify(previousMembers));
    sortedList.sort((a, b) => a.localeCompare(b));
    return JSON.stringify(previousMembers) === JSON.stringify(sortedList);
};

export const TeamProvider = ({ children }) => {
    const [selectedMember, setSelectedMemberInner] = useState('');
    const [showAltMembers, setShowAltMembers] = useState(false);

    const setSelectedMember = useCallback((memberId, name) => {
        if(memberId === selectedMember){
            setSelectedMemberInner('');
            return;
        }
        setSelectedMemberInner(memberId);
        previousMembers.push(name);
        if(!showAltMembers){
            const alt = checkMemberList();
            setShowAltMembers(alt);
            if(alt){
                setSelectedMemberInner('');
            }
        }
    }, [selectedMember, setSelectedMemberInner, showAltMembers]);

    const value = useMemo(() => ({
        selectedMember,
        setSelectedMember,
        showAltMembers
    }), [selectedMember, setSelectedMember, showAltMembers]);

    return <TeamContext.Provider value={value}>{children}</TeamContext.Provider>;
};

export const useTeamContext = () => {
    return useContext(TeamContext);
}