import styles from './events.module.css';
import {PageTitle} from "../components/page-title";
import {EndingLogo} from "../components/ending-logo";
import {EventsList} from "./events-list";
import {Event} from "./event";
import {AndMore} from "../components/and-more";

export const Foa2024Events = () => {
    return (
        <div className={styles['events-page']}>
            <PageTitle text='Events for FoA 2024' />
            <div className={styles['event-list']}>
                {
                    EventsList.map((event) => (
                        <Event event={event} key={event.title} />
                    ))
                }
            </div>
            <AndMore />
            <EndingLogo />
        </div>
    );
};