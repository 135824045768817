import {useCallback, useRef} from "react";
import './team.css';
import {TeamGrid} from "./team-grid";
import {TeamProvider} from "./team-context";
import {MemberDetails} from "./member-details";
import {MemberVideos} from "./member-videos";
import {TeamVideos} from "./team-videos";
import toastyWebm from './toasty.webm';
import {TeamHeader} from "./team-header";
import {shouldUseWebm} from "../../should-use-webm";

export const TeamPage = () => {
    const videoRef = useRef();
    const playToasty = useCallback(() => {
        videoRef.current.play();
    }, [videoRef]);

    return (
        <div className='team-page'>
            <div className='team-content'>
                <div className='team-section'>
                    <TeamProvider>
                        <TeamHeader />
                        <TeamGrid/>
                        <MemberDetails playToasty={playToasty} />
                        <MemberVideos />
                        <TeamVideos />
                    </TeamProvider>
                </div>
            </div>
            {
                shouldUseWebm() && (
                    <video className='team-toasty' src={toastyWebm} ref={videoRef} />
                )
            }
        </div>
    )
};