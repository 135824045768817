import colorVortex from '../../assets/VortexC.svg';
import colorText from '../../assets/TypeC.svg';
import './landing.css';

export const LandingPage = () => {
    return (
        <div className="landing-page">
            <div className="main-logo">
                <div className="main-logo-content">
                    <img className="main-vortex" src={colorVortex} alt='landing page logo vortex'/>
                    <img className="main-logo-text" src={colorText} alt='lading page logo text'/>
                </div>
            </div>
        </div>
    )
};