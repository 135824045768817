import {Routes, Route} from "react-router-dom";
import './App.css';
import {Layout} from "./layout";
import {LandingPage} from "./pages/landing";
import {TeamPage} from "./pages/team";
import {BlogPage} from "./pages/blog";
import {AboutPage} from "./pages/about";
import {EasterEggs} from "./easter-eggs";
import {SecretPage} from "./pages/secret";
import {PrototypeLayout} from "./pages/prototype/prototype-layout";
import {PrototypeLanding} from "./pages/prototype/prototype-landing";
import {PrototypeLogs} from "./pages/prototype/prototype-logs";
import {PrototypeAbout} from "./pages/prototype/prototype-about";
import {FightingGameIntro} from "./pages/misc/fighting-game-intro";
import {MiscLayout} from "./pages/misc/misc-layout";
import {MiscLanding} from "./pages/misc/misc-landing";
import {Foa2024Layout} from "./pages/events/foa2024/foa2024-layout";
import {Foa2024Landing} from "./pages/events/foa2024/landing";
import {Foa2024Events} from "./pages/events/foa2024/events";
import {Foa2024Guests} from "./pages/events/foa2024/guests";
import {Foa2024Games} from "./pages/events/foa2024/games";


function App() {
    return (
        <div className="app">
            <EasterEggs/>
            <Routes>
                <Route path="/" element={<Layout/>}>
                    <Route index element={<LandingPage/>}/>
                    <Route path="team" element={<TeamPage/>}/>
                    <Route path="blog">
                        <Route index element={<BlogPage />}/>
                        <Route path=':blogId' element={<BlogPage />}/>
                    </Route>
                    <Route path="about" element={<AboutPage/>}/>
                    <Route path='secret' element={<SecretPage/>}/>
                    <Route path="prototype" element={<PrototypeLayout />}>
                        <Route index element={<PrototypeLanding />}/>
                        <Route path='logs' element={<PrototypeLogs />}/>
                        <Route path='about' element={<PrototypeAbout />}/>
                    </Route>
                    <Route path='misc' element={<MiscLayout />}>
                        <Route index element={<MiscLanding />} />
                        <Route path='fighting-game' element={<FightingGameIntro />} />
                    </Route>
                    <Route path='foa2024' element={<Foa2024Layout />}>
                        <Route index element={<Foa2024Landing />} />
                        <Route path='events' element={<Foa2024Events />} />
                        <Route path='guests' element={<Foa2024Guests />} />
                        <Route path='games' element={<Foa2024Games />} />
                    </Route>
                </Route>
            </Routes>
        </div>

    );
}

export default App;
