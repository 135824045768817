import styles from './games.module.css';
import {GamesList} from "./games-list";
import moreGames from './more.png';
import {Game} from "./game";
import {PageTitle} from "../components/page-title";
import {EndingLogo} from "../components/ending-logo";

export const Foa2024Games = () => {
    return (
        <div className={styles['games-page']}>
            <PageTitle text='Featured Games for FoA 2024' />
            <div className={styles['games-list']}>
                {
                    GamesList.map((game, index) => (
                        <Game key={game.id} game={game} isFlipped={index % 2 === 0} />
                    ))
                }
            </div>
            <div className={styles['more-games']}>
                <div>
                    And many more games are in store!
                </div>
                <img src={moreGames} alt='other games featured at the festival' />
            </div>
            <EndingLogo />
        </div>
    );
};