import styles from './guest.module.css';
import twitter from '../social-icons/twitter.png';
import twitch from '../social-icons/twitch.png';
import youtube from '../social-icons/youtube.png';
import website from '../social-icons/website.png';

const typeToImageMap = {
    'twitter': twitter,
    'twitch': twitch,
    'youtube': youtube,
    'website': website,
};

export const Guest = ({guest}) => {
    const {
        name,
        tagline,
        description,
        profile,
        socials,
    } = guest;
    return (
        <div className={styles.guest}>
            <div className={styles.content}>
                <div>
                    <div className={styles.name}>
                        {name}
                    </div>
                    <div className={styles.tagline}>
                        {tagline}
                    </div>
                    <div className={styles.description}>
                        {description}
                    </div>
                </div>

                <div className={styles.socials}>
                    {
                        socials.map(({type, url}) => (
                            <a href={url} className={styles.social}>
                                <img src={typeToImageMap[type]} alt={url} />
                            </a>
                        ))
                    }
                </div>
            </div>
            <img src={profile} alt={`${name} looking cool`} />
        </div>
    )
}