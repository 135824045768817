import styles from "./page-title.module.css";
import festivalLogo from "../../festivalLogo.png";

export const PageTitle = ({text}) => {
        return (
            <div className={styles['page-title']}>
                <img src={festivalLogo} alt='foa festival logo'/>
                <div>
                    {text}
                </div>
            </div>
        )
    };