import styles from './game.module.css';

export const Game = ({game, isFlipped}) => {
    const {id, screenshot, logo, character, text} = game;
    return (
        <div className={`${styles.game} ${isFlipped ? styles.flipped : ''}`}>
            <div className={styles.content}>
                <img className={styles.character}  src={character} alt={`${id} character`} />
                <div className={styles['logo-and-text']}>
                    <img src={logo} alt={`${id} logo`} />
                    <div>
                        {text}
                    </div>
                </div>
            </div>
            <img src={screenshot} alt={`${id} screenshot`}/>
        </div>
    );
};