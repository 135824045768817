import {useTeamContext} from "../team-context";
import {useMemberFromMemberId} from "../../../hooks/use-member-from-member-id";
import {VideoList} from "../video-list";
import './member-videos.css';

export const MemberVideos = () => {
    const {selectedMember} = useTeamContext();
    const member = useMemberFromMemberId(selectedMember);
    if(!member){
        return null;
    }
    return (
        <div className={`member-videos ${member.mainPlatform === 'twitch' ? 'twitch' : ''}`}>
            <VideoList videos={member?.clips} title='My Clips' />
            <VideoList isSmall videos={member?.videos} title='My Latest Videos' />
            <VideoList isSmall videos={member?.vods} title='Full Vods' />
        </div>
    );
};