import styles from './foa2024-landing.module.css';
import sun from './sun.png';
import wheel from './wheel.png';
import festivalLogo from '../festivalLogo.png';
import {NavLink} from "react-router-dom";

import chao from './chao.webp';
import puyo from './puyo.png';
import kapu from './kapu.webp';
import othermoro from './othermoro.webp';
import amiga from './amiga.png';
import jetsetdog from './jetsetdog.webp';

import igtLogo from './invalidGatewayLogo.png';
import ggLogo from './glitchyGearheads.png';
import evoLogo from './eggmanRevo.png';
import rl902Logo from './902RL_Logo.png';

export const Foa2024Landing = () => {
    return (
        <div className={styles['landing-page']}>
            <div className={styles.welcome}>
                <img src={sun} alt='foa sun' />
                <div className={styles['welcome-text']}>
                    Welcome to
                </div>
                <img src={wheel} alt='foa wheel' />
            </div>
            <img className={styles['big-logo']} src={festivalLogo} alt='main logo for Festival of Ages 2024' />
            <div className={styles['divider']} />
            <div className={styles['text-blurbs']}>
                <div className={`${styles['text-blurb']}`}>
                    <img src={chao} alt='chao' />
                    <div>
                        "Festival of AGES", or FoA for short, is a rebranding of Glitch_Kitten's "Summer of Sonic" homage festival, to expand across all things SEGA.
                    </div>
                </div>
                <div className={`${styles['text-blurb']} ${styles.right}`}>
                    <img src={puyo} alt='puyo' />
                    <div>
                        People will be able to see SEGA games, join in on Events, and meet a lot of Guests who share their love for Sonic and SEGA.
                    </div>
                </div>
                <div className={`${styles['text-blurb']}`}>
                    <img src={kapu} alt='kapu' />
                    <div>
                        For more info on what <NavLink to='/foa2024/games'>Games</NavLink>, <NavLink to='/foa2024/events'>Events</NavLink>, and <NavLink to='/foa2024/guests'>Guests</NavLink> will be at the Festival, check out the corresponding pages!
                    </div>
                </div>
                <div className={`${styles['text-blurb']} ${styles.right}`}>
                    <img src={othermoro} alt='othermoro' />
                    <div>
                        Invalid Gateway is helping out with organizing for the celebration. Click on the link below to learn more about the team!
                    </div>
                </div>
                <div className={`${styles['text-blurb']} ${styles.center}`}>
                    <div>
                        Also, consider following the Invalid Gateway team on <a href='https://twitter.com/Invalid_Gateway'>Twitter</a> or joining the <a href='https://discord.gg/2GAtbauEWJ'>Glitchy Gateway Discord</a> server to get festival updates.
                    </div>
                </div>
                <div className={styles.ending}>
                    <img src={amiga} alt='amiga' />
                    <div>
                        Hope to see you at the festival!
                    </div>
                    <img src={jetsetdog} alt='jet set radio dog' />
                </div>
                <div className={styles.associated}>
                    <div className={styles['associated-title']} >Associated Organizations</div>
                    <div className={styles['associated-links']}>
                        <a href='https://invalidgateway.com/'>
                            <img src={igtLogo} alt='invalid gateway logo' />
                        </a>
                        <a href='https://discord.gg/2GAtbauEWJ'>
                            <img src={ggLogo} alt='glitchy gearhead logo' />
                        </a>
                        <a href='https://www.sonicrevolution.net//'>
                            <img src={evoLogo} alt='sonic revolution logo' />
                        </a>
                        <a href='https://youtube.com/@skull902?si=HKNiEsRL_KY_ZSOz'>
                            <img src={rl902Logo} alt='902 rl logo' />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};