import './section-header.css';

export const SectionHeader = ({text, isSmall}) => {
    return (
        <div className={`section-header ${isSmall ? 'small' : ''}`}>
            <div className='section-header-text'>
                {text}
            </div>
        </div>
    );
};