import {useCallback, useEffect, useRef, useState} from "react";
import hadouken from './hadouken.mp3';
import sonicTrick1 from './sonicTrick1.mp3';
import sonicTrick2 from './sonicTrick2.mp3';
import sonicTrick3 from './sonicTrick3.mp3';
import sonicTrick4 from './sonicTrick4.mp3';
import sonicTrick5 from './sonicTrick5.mp3';
import {Bluescreen} from "./bluescreen";
import spark from "./SparkHAHA.gif";
import ragingStorm from "./ragingStorm.webp";
import ragingStormSound from "./ragingStorm.mp3";
import './easter-eggs.css';
import {useLocation} from "react-router-dom";
import gkSeesYou from "./GK_seesyou.mp3";

const trickSounds = [sonicTrick1, sonicTrick2, sonicTrick3, sonicTrick4, sonicTrick5];
let init = false;
let keyBuffer = [];
let rCount = 0;
let waitingTimeout;

export const EasterEggs = () => {
    const location = useLocation();
    const audioRef = useRef();
    const sparkRef = useRef();
    const ragingStormRef = useRef();
    const [showBlueScreen, setShowBluescreen] = useState(false);

    const showSpark = useCallback(() => {
        sparkRef.current.style.top = `${Math.round(Math.random() * 80)}%`;
        sparkRef.current.style.left = `${Math.round(Math.random() * 80)}%`;
        sparkRef.current.style.opacity = 1;
        setTimeout(() => {
            sparkRef.current.style.opacity = 0;
        }, 3000);
    }, [sparkRef]);

    const showRagingStorm = useCallback(() => {
        ragingStormRef.current.style.opacity = 1;
        audioRef.current.src = ragingStormSound;
        audioRef.current.play();
        setTimeout(() => {
            ragingStormRef.current.style.opacity = 0;
        }, 2000);
    }, [ragingStormRef, audioRef]);


    const checkHadouken = useCallback((keys) => {
        if(keys.length < 3){
            return false;
        }
        const lastThree = keys.slice(-3);
        const isHadouken = lastThree[0] === 'ArrowDown' && lastThree[1] === 'ArrowRight' && lastThree[2] === 'p';
        if(isHadouken){
            audioRef.current.src = hadouken;
            audioRef.current.play();
        }
        return isHadouken;
    }, [audioRef]);

    const checkSpark = useCallback((keys) => {
        if(keys.length < 5){
            return false;
        }
        const lastFive = keys.slice(-5);
        const string = lastFive.join('').toLowerCase();
        const isSpark = string === 'spark';
        if(isSpark){
            showSpark();
        }
        return isSpark;
    }, [showSpark]);

    const checkRagingStorm = useCallback((keys) => {
        if(keys.length < 8){
            return false;
        }
        const lastFive = keys.slice(-8);
        const string = lastFive.join('').toLowerCase();
        const isRagingStorm = string === '1632143a';
        if(isRagingStorm){
            showRagingStorm();
        }
        return isRagingStorm;
    }, [showRagingStorm]);

    const updateKeyBuffer = useCallback((key) => {
        let newKeyBuffer = [...keyBuffer, key];
        if(newKeyBuffer.length >=50){
            newKeyBuffer = newKeyBuffer.slice(-50);
        }
        if(key === 'r'){
            rCount++;
            if(rCount === 100){
                setShowBluescreen(true);
                rCount = 0;
            }
            if(rCount > 20){
                audioRef.current.src = trickSounds[Math.round(Math.random() * 1000) % trickSounds.length];
                try {
                    audioRef.current.play();
                } catch {

                }
            }
        }
        const didSpark = checkSpark(newKeyBuffer);
        const didHadouken = checkHadouken(newKeyBuffer);
        const didRagingStorm = checkRagingStorm(newKeyBuffer);
        if(didHadouken || didSpark || didRagingStorm){
            keyBuffer = [];
            return;
        }
        keyBuffer = newKeyBuffer;
    }, [checkHadouken, audioRef, checkSpark, checkRagingStorm]);

    useEffect(() => {
        if(waitingTimeout){
            clearTimeout(waitingTimeout);
            waitingTimeout = undefined;
        }
        waitingTimeout = setTimeout(() => {
            audioRef.current.src = gkSeesYou;
            audioRef.current.play();
        }, 360000);
    }, [location]);



    useEffect(() => {
        if(init){
            return;
        }
        init=true;
        console.log(`
              ++             +++           
            +++       ++++++++            
           +++    ++++++++++              
          ++++ ++++++++++++               
         ++++++++++++++++++++++++         
        +++++++++++++++++++++++++++++     
 ++    +++++++++++++    ++++++++++++++++  
 +++   ++++++++            +++++++++++++++
  ++++++++++++                ++++++      
  +++++++++++                 +++++++     
   ++++++++                   ++++++++    
   ++++++++                    ++++++++   
    ++++++++                   ++++++++   
     ++++++++                +++++++++++  
      ++++++                 +++++++++++  
+++++++++++++++             +++++++   +++ 
  ++++++++++++++++    +++++++++++++    ++ 
     +++++++++++++++++++++++++++++        
          +++++++++++++++++++++++         
               ++++++++++++ +++++         
              ++++++++++    ++++          
            ++++++++        ++            
           +++             ++
                        
Welcome to the Invalid Gateway website! There are 20 Easter Eggs if you find them all!
If you need some hints type out "window.getHints()" here!
        `);

        window.getHints = () => {
            console.log(`
Here are the hints!
1. The logo looks fun!
2. Who is tts gk's favorite? XD
3. Sheng Long's pupil loves this move.
4. What's the trick button again?
5. This place would eat up any gamer's weekly allowance.
6. Burenyuu~~!
7. Sonic's not the only sega that goes fast!
8. You can't predict mr beast!
9. Like a library
10. Needed to start a fire.
11. Havoc, disorder, pandemonium and the like with friends.
12. Patience is a virtue
13. A simulacra of the best mode of transportation
14. Da alien
15. The geese love this kind of pretzel.
16. Playing the flute on a stormy night is a way to become a legend. 
17. Like eggs, songs eventually go bad.
18. Where the magic happens.
19. Chile's strongest soldier.
20. If you don't shut up, you're gonna break something...
            
            `);
        };

        window.addEventListener('keydown', (e) => {
            updateKeyBuffer(e.key);
        })

    }, [updateKeyBuffer]);

    return (
        <>
            <audio ref={audioRef} />
            {
                showBlueScreen && (
                    <Bluescreen setShowBluescreen={setShowBluescreen} />
                )
            }
            <img className='spark-image' ref={sparkRef} src={spark} alt='star prince' />
            <img className='raging-storm' ref={ragingStormRef} src={ragingStorm} alt='raging storm' />
        </>
    );
};