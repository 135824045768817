import {useMemo, useState, useCallback} from "react";
import {NavLink, useParams} from "react-router-dom";
import MarkDown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import {usePromiseEffect} from "../../hooks/use-promise-effect";
import './blog-page.css';
import PostData from './post-data';
import {SectionHeader} from "../team/section-header";

const shortMonths = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

export const BlogPage = () => {
    const [archiveOpen, setArchiveOpen] = useState(false);
    const { blogId } = useParams();
    const matchingBlog = PostData.find(p => p.filename === `${blogId}.md`) || PostData[0];
    const blogIndex = PostData.findIndex(p => p.filename === matchingBlog.filename) || 0;
    const previousBlog = blogIndex === PostData.length -1 ? undefined : PostData[blogIndex + 1];
    const nextBlog = blogIndex === 0 ? undefined : PostData[blogIndex - 1];
    const blogDate = new Date(matchingBlog.timestamp);
    const currentBlogMarkdown = usePromiseEffect(async () => {
        if(!matchingBlog){
            return;
        }
        const filePath = await import(`./posts/${matchingBlog.filename}`);
        const fileResponse = await fetch(filePath.default);
        const fileContents = await fileResponse.text();
        return fileContents;
    }, [matchingBlog]);
    const validContent = useMemo(() => {
        if(!currentBlogMarkdown?.value){
            return 'Loading...';
        }
        const content = currentBlogMarkdown?.value;
        const lines = content.split('\n');
        lines.shift();
        lines.shift();
        lines.shift();
        lines.shift();
        lines.shift();

        return lines.join('\n');
    }, [currentBlogMarkdown]);
    const title = useMemo(() => {
        const content = currentBlogMarkdown?.value || 'Title';
        const lines = content.split('\n');
        return lines[0];
    }, [currentBlogMarkdown]);
    const toggleArchiveOpen = useCallback(() => {
        setArchiveOpen(v => !v);
    }, [setArchiveOpen]);
    return (
        <div className='blog-page'>
            <div className='blog-page-content'>
                <div className='blog-main-section'>
                    <SectionHeader text={title} />
                    <div className='blog-main-section-content'>
                        <div className='blog-date-header'>
                            <div className='blog-date'>
                                {blogDate.getDate()} {shortMonths[blogDate.getMonth()]} {blogDate.getFullYear()}
                            </div>
                            <div className='blog-date-spacer'>
                                —
                            </div>
                            <div className='blog-author'>
                                Written by {matchingBlog.author}
                            </div>
                        </div>
                        <div className='blog-content'>
                            <MarkDown remarkPlugins={[remarkGfm]}>{validContent}</MarkDown>
                        </div>
                        <div className='blog-next-back-buttons'>
                            <div className='blog-next-back-button'>
                                {
                                    previousBlog && (
                                        <NavLink to={`/blog/${previousBlog.filename.replace('.md', '')}`}>
                                            <i className='blog-reverse-triangle'>‣</i>
                                            {previousBlog.title}
                                        </NavLink>
                                    )
                                }
                                {
                                    !previousBlog && (
                                        <div className='blog-empty-button'>
                                            You've read it all! Good job :3
                                        </div>
                                    )
                                }
                            </div>
                            <div className='blog-next-back-button'>
                                {
                                    nextBlog && (
                                        <NavLink to={`/blog/${nextBlog.filename.replace('.md', '')}`}>
                                            {nextBlog.title}
                                            <i>‣</i>
                                        </NavLink>
                                    )
                                }
                                {
                                    !nextBlog && (
                                        <div className='blog-empty-button'>
                                            Make sure to follow for more updates!
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`blog-archive-sidebar ${archiveOpen ? 'open' : ''}`}>
                    <div className='blog-archive-content'>
                        <div className='blog-archive-header'>
                            ARCHIVES
                        </div>
                        <div className='blog-archive-list'>
                            {
                                PostData.map(({title, filename}) => {
                                    return (
                                        <div className='blog-archive-item' key={filename}>
                                            <div className='blog-archive-spacer'></div>
                                            <NavLink key={filename} to={`/blog/${filename.replace('.md', '')}`}>{title}</NavLink>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
                <button className={`blog-open-archive-button ${archiveOpen ? 'open' : ''}`} onClick={toggleArchiveOpen}>
                    <span>{'<'}</span>
                </button>
            </div>
        </div>
    )
};