import {useCallback, useState} from 'react';
import styles from './header.module.css';
import sun from './sun.png';
import wheel from './wheel.png';
import {NavLink} from "react-router-dom";
import logo from '../festivalLogo.png';

export const Header = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = useCallback(() => {
        setIsOpen(o => !o);
    }, [setIsOpen]);


    return (
        <div className={styles['header']}>
            <div className={styles['icon']}>
                <img src={sun} alt='foa sun' />
            </div>
            <button className={styles['hamburger-button']}>
                <button className={styles['hamburger-button']} onClick={toggleOpen}>
                    <svg height="32px" viewBox="0 0 32 32" width="32px" ><path d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z"/></svg>
                </button>
            </button>
            <div className={styles['nav-logo']}>
                <img src={logo} alt='foa logo' />
            </div>
            <div className={`${styles['nav-items']} ${isOpen ? styles.open : ''}`}>
                <button className={styles['x-button']} onClick={toggleOpen}>
                    <svg x="0px" y="0px" viewBox="0 0 96 96">
                        <polygon points="96,14 82,0 48,34 14,0 0,14 34,48 0,82 14,96 48,62 82,96 96,82 62,48 "/>
                    </svg>
                </button>
                <NavLink to="/foa2024/" end className={({isActive}) => isActive ? styles.active : ''}>
                    Home
                </NavLink>
                <span>
                    &mdash;
                </span>
                <NavLink to="/foa2024/games" className={({isActive}) => isActive ? styles.active : ''}>
                    Games
                </NavLink>
                <span>
                    &mdash;
                </span>
                <NavLink to="/foa2024/events" className={({isActive}) => isActive ? styles.active : ''}>
                    Events
                </NavLink>
                <span>
                    &mdash;
                </span>
                <NavLink to="/foa2024/guests" className={({isActive}) => isActive ? styles.active : ''}>
                    Guests
                </NavLink>
            </div>
            <div className={styles['icon']}>
                <img src={wheel} alt='foa wheel' />
            </div>
        </div>
    )
};